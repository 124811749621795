import { Route, Routes } from "react-router-dom";
import "./App.css";

function HomePage() {
  return (
    <div className="App">
      <header className="App-header">
        <p>LangAdventure Home</p>
      </header>
    </div>
  );
}

function ContactPage() {
  return (
    <div className="App">
      <header className="App-header">
        <p>LangAdventure Contact</p>
      </header>
    </div>
  );
}

function App() {
  return (
    <>
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </main>
    </>
  );
}

export default App;
